<template>
  <RouterView />
</template>

<script setup>
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'

function safariHacks() {
  document.addEventListener('touchstart', (event) => {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })

  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    (event) => {
      const now = new Date().getTime()

      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }

      lastTouchEnd = now
    },
    false
  )

  document.addEventListener('gesturestart', (event) => {
    event.preventDefault()
  })

  function convertStyle() {
    document.body.style.setProperty('height', `${window.innerHeight}px`)
  }

  window.addEventListener('resize', convertStyle)
  window.addEventListener('DOMContentLoaded', convertStyle)
}

onMounted(() => {
  safariHacks()
})
</script>
