import { createRouter, createWebHistory } from 'vue-router'

const constantRoute = [
  {
    path: '',
    redirect: '/main'
  },
  {
    path: '/main',
    component: () => import('@/views/MainMenu')
  },
  {
    path: '/chat',
    component: () => import('@/views/Chat')
  },
  {
    path: '/chatPractice',
    component: () => import('@/views/ChatPractice')
  },
  {
    path: '/translator',
    component: () => import('@/views/Translator')
  },
  {
    path: '/DnDModule',
    component: () => import('@/views/DnDModule')
  },
  {
    path: '/imageGenerator',
    component: () => import('@/views/ImageGenerator')
  },
  {
    path: '/login',
    component: () => import('@/views/Login')
  },
  {
    path: '/test',
    component: () => import('@/views/Test')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: constantRoute
})

export default router
