import router from '@/router'
import Cookies from 'js-cookie'
import useUserStore from '@/stores/user'

const TokenKey = 'Authorization'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function goLogin() {
  useUserStore().logoutAsync()
  const pathname = location.pathname
  router.replace(`/login?redirect=${encodeURIComponent(pathname)}`)
}
