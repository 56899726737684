import axios from 'axios'
import { ElNotification } from 'element-plus'
import router from '@/router'
import errorCode from '@/utils/errorCode'
import { tansParams } from '@/utils/tool'
import { goLogin } from '@/utils/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const request = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 180000
})

request.interceptors.request.use(
  (config) => {
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    if (response.data.code && response.data.code !== 200) {
      ElNotification.error({
        title: errorCode.default,
        message: response.data.msg
      })
      return Promise.reject(response)
    }
    return response.data
  },
  (error) => {
    console.log('error', error)
    if (error.code === 'ECONNABORTED') {
      ElNotification.error({
        title: errorCode[error.code],
        message: error.code
      })
    } else {
      if (error.response.status === 401) {
        ElNotification.error({
          title: errorCode[error.response.status],
          message: error.response.data.msg
        })
        goLogin()
      } else {
        ElNotification.error({
          title: errorCode.error,
          message: error.response.data.msg
        })
      }
    }

    return Promise.reject(error)
  }
)

export default request
