import '@/assets/style/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import moment from 'moment'
import 'moment/dist/locale/zh-cn'
import 'highlight.js/styles/a11y-dark.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import doPermission from './permission'

// import VConsole from 'vconsole'
// const vConsole = new VConsole()

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
})

moment.locale('zh-cn')

import App from './App'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)
doPermission()
app.use(vuetify)

app.mount('#app')
